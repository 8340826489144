<template>
  <div id="presetVersion-select" class="w-100">
    <v-autocomplete
      ref="presetVersionSelect"
      v-model="presetVersion"
      :items="presetVersionsSorted"
      item-value="id"
      item-text="version"
      dense
      filled
      hide-details
      return-object
      :attach="'#presetVersion-select'"
      :menu-props="{ offsetY: true, closeOnClick: true }"
      :no-data-text="$t('general.noSelectOptions')"
      class="select-presetVersion pt-3"
    >
      <template #prepend-inner>
        <v-icon small class="pl-2 mr-1" v-text="'fa-solid fa-code-compare'" />
      </template>
      <template #selection="{ item }">
        <span class="selected-presetVersion pa-0">
          {{ $t("general.presetVersion", { version: item.version }) }}
        </span>
      </template>
      <template #item="{ item }">
        <span class="selected-presetVersion font-weight-medium">
          {{ $t("general.presetVersion", { version: item.version }) }}
        </span>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  LOAD_PRESET_VERSIONS,
  UPDATE_SELECTED_PRESET_VERSION
} from "@/core/services/store/switchArea.module";

export default {
  computed: {
    ...mapGetters(["presetVersions", "selectedPresetVersion"]),
    presetVersionsSorted: function () {
      return this.presetVersions
        .slice()
        .sort((a, b) => b.version - a.version)
        .map(version => {
          if (version.is_dev_version) {
            version.version += " (dev)";
          }
          return version;
        });
    },
    presetVersion: {
      get() {
        return this.selectedPresetVersion;
      },
      set(value) {
        this.$store.dispatch(UPDATE_SELECTED_PRESET_VERSION, value);
      }
    }
  },
  watch: {
    presetVersionsSorted: function () {
      if (!this.presetVersionsSorted?.[0]) {
        return;
      }
      this.presetVersion = this.presetVersionsSorted[0];
    }
  },
  mounted() {
    this.$store.dispatch(LOAD_PRESET_VERSIONS);
  }
};
</script>

<style scoped lang="scss">
:deep(.select-presetVersion) {
  width: 250px;
  color: $dark;

  &.v-input {
    .v-input__control {
      .v-input__slot {
        background: $color-connect-background;
        border-radius: 8px;

        &::before {
          border: none;
        }

        &::after {
          border: none;
        }

        .v-input__prepend-inner {
          margin: auto;
        }
      }
    }
  }
}

:deep(
    .theme--light.v-text-field--filled:not(.v-input--is-focused):not(
        .v-input--has-state
      )
      > .v-input__control
      > .v-input__slot:hover
  ) {
  background: darken($color-connect-background, 5%);
}

:deep(
    .v-text-field--enclosed.v-input--dense:not(
        .v-text-field--solo
      ).v-text-field--single-line
      .v-input__append-inner
  ) {
  margin: auto;
}
</style>
