<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "BetaBadge"
});
</script>

<template>
  <div class="beta-badge border-bottom py-2 bg-white">
    <div class="beta-badge-topbar align-items-center">
      <div class="beta-badge-topbar-item beta-text text-h5 ma-auto">
        {{ $t("general.beta") }}
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.beta-badge {
  position: sticky;
  top: 0;
  z-index: 200;
}

.beta-badge-topbar {
  flex: 1 1 0;
  display: flex;
  height: 100%;
}

.beta-badge-topbar-item {
  display: -webkit-box;
}

.beta-text {
  color: #f44336;
}
</style>
