<template>
  <div class="d-flex h-100">
    <div class="flex-column flex-grow-0 border-right connect-sidebar">
      <Sidebar />
    </div>
    <div
      class="flex-column flex-row flex-grow-1 main-panel-wrapper overflow-hidden"
    >
      <div class="main-panel position-relative">
        <Progressbar />
        <BetaBadge v-if="beta" />
        <div class="py-4 px-6 h-100">
          <router-view />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import Sidebar from "@/layout/Sidebar.vue";
import { computed } from "vue";
import BetaBadge from "@/layout/BetaBadge.vue";
import Progressbar from "@/components/Tools/Progressbar.vue";

const beta = computed(() => {
  return process.env?.VUE_APP_BETA?.toLowerCase() === "true";
});
</script>

<style scoped lang="scss">
.grid {
  display: grid;
  grid-template-rows: auto 1fr auto;
  min-height: 100vh;
}
.main-panel {
  background-color: $color-connect-background;
  min-height: 100%;
  height: 100%;
}
.connect-sidebar {
  height: 100vh;
  position: sticky;
  top: 0;
}
</style>
