<template>
  <div id="project-select" class="w-100">
    <v-autocomplete
      ref="projectSelect"
      v-model="project"
      :items="projectsSorted"
      item-value="id"
      item-text="name"
      dense
      filled
      hide-details
      return-object
      :attach="'#project-select'"
      :menu-props="{ offsetY: true, closeOnClick: true }"
      :no-data-text="$t('general.noSelectOptions')"
      class="select-project pt-3"
    >
      <template #prepend-inner>
        <v-icon
          small
          class="pl-2 mr-1"
          v-text="'fa-solid fa-diagram-project'"
        />
      </template>
      <template #selection="{ item }">
        <span class="selected-project pa-0">
          {{ item.name }}
        </span>
      </template>
      <template #item="{ item }">
        <span class="selected-project font-weight-medium">
          {{ item.name }}
        </span>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  LOAD_PROJECTS,
  UPDATE_SELECTED_PROJECT
} from "@/core/services/store/switchArea.module";

export default {
  computed: {
    ...mapGetters(["projects", "selectedProject"]),
    projectsSorted: function () {
      return this.projects
        .slice()
        .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));
    },
    project: {
      get() {
        return this.selectedProject;
      },
      set(value) {
        this.$store.dispatch(UPDATE_SELECTED_PROJECT, value);
      }
    }
  },
  mounted() {
    this.$store.dispatch(LOAD_PROJECTS);
  }
};
</script>

<style scoped lang="scss">
:deep(.select-project) {
  width: 250px;
  color: $dark;

  &.v-input {
    .v-input__control {
      .v-input__slot {
        background: $color-connect-background;
        border-radius: 8px;

        &::before {
          border: none;
        }

        &::after {
          border: none;
        }

        .v-input__prepend-inner {
          margin: auto;
        }
      }
    }
  }
}

:deep(
    .theme--light.v-text-field--filled:not(.v-input--is-focused):not(
        .v-input--has-state
      )
      > .v-input__control
      > .v-input__slot:hover
  ) {
  background: darken($color-connect-background, 5%);
}

:deep(
    .v-text-field--enclosed.v-input--dense:not(
        .v-text-field--solo
      ).v-text-field--single-line
      .v-input__append-inner
  ) {
  margin: auto;
}
</style>
