<script>
import menu from "@/core/config/menu.json";
import { mapGetters } from "vuex";
import ProjectDropdown from "@/layout/ProjectDropdown.vue";
import PresetVersionsDropdown from "@/layout/PresetVersionsDropdown.vue";
import Auth from "@/components/General/Auth/auth";
import i18nService from "@/core/services/i18n.service";
import Users from "@/components/Settings/Users/users";

export default {
  components: {
    ProjectDropdown,
    PresetVersionsDropdown
  },
  data() {
    return {
      drawer: true,
      mini: false,
      userMenu: false,
      languageMenu: false,
      languages: i18nService.languages,
      languageBusy: false
    };
  },
  computed: {
    ...mapGetters(["selectedProject", "currentUserPersonalInfo"]),
    ...mapGetters("instance", ["instance"]),
    menuItems: function () {
      return menu;
    },
    getFullName() {
      return (
        this.currentUserPersonalInfo.name +
        " " +
        this.currentUserPersonalInfo.surname
      );
    },
    activeLanguage() {
      return i18nService.getActiveLanguage();
    },
    projectVersion() {
      if (!this.instance?.projectPresetVersions || !this.selectedProject?.id) {
        return null;
      }
      return (
        this.instance.projectPresetVersions?.[this.selectedProject.id] ?? null
      );
    }
  },
  mounted() {
    this.mini = localStorage.getItem("sidebarExpanded") === "true";
  },
  methods: {
    expandSidebar() {
      this.mini = !this.mini;
      localStorage.setItem("sidebarExpanded", this.mini);
    },
    logout() {
      Auth.logout();
      this.$router.push({ name: "login" });
    },
    selectedLanguage(lang) {
      if (i18nService.getActiveLanguage() === lang) {
        return;
      }
      this.languageBusy = true;
      i18nService.setActiveLanguage(lang);
      let body = {
        email: this.currentUserPersonalInfo.email,
        language_id: lang === "de" ? 2 : 1,
        name: this.currentUserPersonalInfo.name
      };
      Users.update(this.currentUserPersonalInfo.id, body)
        .then(() => {
          window.location.reload();
        })
        .catch(() => {
          this.languageBusy = false;
        });
    }
  }
};
</script>

<template>
  <v-navigation-drawer
    v-model="drawer"
    :mini-variant="mini"
    permanent
    floating
    class="sidebar"
  >
    <!------------ START: Sidebar top ------------>
    <v-list class="pt-0" :class="!mini ? 'px-3' : 'px-2'">
      <!------------ START: Image ------------>
      <v-list-item
        class="mb-0 mt-2 logo"
        :class="mini ? 'pa-0' : ''"
        @click="expandSidebar"
      >
        <v-list-item-content>
          <img
            v-if="mini"
            class="logo-height-mini my-6"
            src="../assets/img/logo_icon.svg"
            alt=""
          />
          <img
            v-else
            class="logo-height my-6"
            src="../assets/img/logo.svg"
            alt=""
          />
          <v-icon v-if="mini" small v-text="'fa-solid fa-chevrons-right'" />
        </v-list-item-content>

        <v-icon
          v-if="!mini"
          small
          class="ma-auto"
          v-text="'fa-solid fa-chevrons-left'"
        />
      </v-list-item>
      <!------------ END: Image ------------>

      <!------------ START: Project ------------>
      <v-list-item
        v-b-popover.hover.right="{
          content: $t('menu.project'),
          disabled: !mini
        }"
        class="project"
        :class="!mini ? 'px-0' : 'pa-0'"
      >
        <v-list-item-icon v-if="mini" :class="!mini ? 'mr-1' : 'ma-auto'">
          <v-icon
            small
            class="ma-auto"
            v-text="'fa-solid fa-diagram-project'"
          />
        </v-list-item-icon>

        <v-list-item-content class="p-0">
          <ProjectDropdown />
        </v-list-item-content>
      </v-list-item>
      <!------------ END: Project ------------>

      <!------------ START: PresetVersion ------------>
      <v-list-item
        v-if="instance?.isPresetInstance"
        v-b-popover.hover.right="{
          content: $t('menu.project'),
          disabled: !mini
        }"
        class="project"
        :class="!mini ? 'px-0' : 'pa-0'"
      >
        <v-list-item-icon v-if="mini" :class="!mini ? 'mr-1' : 'ma-auto'">
          <v-icon
            small
            class="ma-auto"
            v-text="'fa-solid fa-diagram-project'"
          />
        </v-list-item-icon>

        <v-list-item-content class="p-0">
          <PresetVersionsDropdown />
        </v-list-item-content>
      </v-list-item>
      <!------------ END: PresetVersion ------------>

      <v-divider class="mx-3 my-2" />

      <!------------ START: Menu items ------------>
      <v-list-item
        v-for="(item, i) in menuItems"
        :key="i"
        v-b-popover.hover.right="{
          content: $t(item.label),
          disabled: !mini
        }"
        active-class="active"
        :to="{ name: item.route }"
        class="mt-1"
        :class="mini ? 'pa-0' : ''"
        dense
      >
        <v-list-item-icon
          :title="$t(item.label)"
          :class="mini ? 'ma-auto' : 'mr-1'"
        >
          <v-icon small class="ma-auto" v-text="item.icon"></v-icon>
        </v-list-item-icon>
        <v-list-item-title>{{ $t(item.label) }}</v-list-item-title>
      </v-list-item>
      <!------------ END: Menu items ------------>
    </v-list>
    <!------------ END: Sidebar top ------------>

    <!------------ START: Sidebar bottom ------------>
    <template #append>
      <v-list class="pt-0" :class="!mini ? 'px-3' : 'px-2'">
        <v-list-item v-if="projectVersion" dense>
          <v-list-item-icon :class="!mini ? 'mr-1' : 'ma-auto'">
            <v-icon small class="ma-auto" v-text="'fa-light fa-code-compare'" />
          </v-list-item-icon>
          <v-list-item-title>{{
            $t("menu.version", { version: projectVersion })
          }}</v-list-item-title>
        </v-list-item>

        <v-divider class="mx-3 my-2" />

        <!------------ START: Settings ------------>
        <v-list-item
          v-if="instance.xentralUrl"
          v-b-popover.hover.right="{
            content: $t('menu.xentralUrl'),
            disabled: !mini
          }"
          active-class="active"
          :href="instance.xentralUrl"
          target="_blank"
          dense
          class="mb-1"
          :class="mini ? 'pa-0' : ''"
        >
          <v-list-item-icon :class="!mini ? 'mr-1' : 'ma-auto'">
            <v-icon
              small
              class="ma-auto"
              v-text="'fa-light fa-arrow-up-right-from-square'"
            />
          </v-list-item-icon>
          <v-list-item-title>
            {{ $t("menu.xentralUrl") }}
          </v-list-item-title>
        </v-list-item>
        <!------------ END: Settings ------------>

        <!------------ START: Settings ------------>
        <v-list-item
          v-b-popover.hover.right="{
            content: $t('menu.projectSettings'),
            disabled: !mini
          }"
          active-class="active"
          :to="{ name: 'projectSettings' }"
          dense
          :class="mini ? 'pa-0' : ''"
        >
          <v-list-item-icon :class="!mini ? 'mr-1' : 'ma-auto'">
            <v-icon small class="ma-auto" v-text="'fa-light fa-gear'" />
          </v-list-item-icon>
          <v-list-item-title>
            {{ $t("menu.projectSettings") }}
          </v-list-item-title>
        </v-list-item>
        <!------------ END: Settings ------------>

        <!------------ START: Support ------------>
        <!--v-list-item
          v-b-popover.hover.right="{
            disabled: !mini
          }"
          active-class="active"
          :to="{ name: 'helpSupport' }"
          dense
          class="mt-1"
          :title="$t('menu.helpSupport')"
          :class="mini ? 'pa-0' : ''"
        >
          <v-list-item-icon :class="!mini ? 'mr-1' : 'ma-auto'">
            <v-icon
              small
              class="ma-auto"
              v-text="'fa-light fa-circle-question'"
            />
          </v-list-item-icon>
          <v-list-item-title>
            {{ $t("menu.helpSupport") }}
          </v-list-item-title>
        </v-list-item-->
        <!------------ END: Support ------------>

        <!------------ START: User Menu ------------>
        <v-menu
          v-model="userMenu"
          right
          nudge-right="5"
          offset-x
          rounded="lg"
          content-class="elevation-1 border"
        >
          <template #activator="{ on, attrs }">
            <v-list-item
              v-b-popover.hover.right="{
                content: $t('menu.account'),
                disabled: !mini
              }"
              dense
              class="mt-1"
              v-bind="attrs"
              :class="mini ? 'pa-0' : ''"
              v-on="on"
            >
              <v-list-item-icon :class="!mini ? 'mr-1' : 'ma-auto'">
                <v-icon small class="ma-auto" v-text="'fa-light fa-user'" />
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ getFullName }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ currentUserPersonalInfo.email }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-icon
                  v-if="userMenu"
                  x-small
                  v-text="'fa-light fa-chevron-right'"
                />
                <v-icon v-else x-small v-text="'fa-light fa-chevron-down'" />
              </v-list-item-action>
            </v-list-item>
          </template>

          <v-list class="px-2">
            <!------------ START: Language Menu ------------>
            <v-menu
              v-model="languageMenu"
              right
              nudge-right="11"
              offset-x
              offset-y
              rounded="lg"
              content-class="elevation-1 border"
            >
              <template #activator="{ on, attrs }">
                <v-list-item dense v-bind="attrs" v-on="on">
                  <v-list-item-icon class="mr-1">
                    <v-icon
                      v-if="!languageBusy"
                      small
                      v-text="'fa-light fa-earth-europe'"
                    />
                    <v-progress-circular
                      v-else
                      indeterminate
                      size="16"
                      width="1"
                      class="my-auto"
                      color="primary"
                    />
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ $t("general.language") }}
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-icon
                      v-if="languageMenu"
                      x-small
                      v-text="'fa-light fa-chevron-right'"
                    />
                    <v-icon
                      v-else
                      x-small
                      v-text="'fa-light fa-chevron-down'"
                    />
                  </v-list-item-action>
                </v-list-item>
              </template>

              <v-list class="px-2">
                <template v-for="(language, i) in languages">
                  <v-list-item
                    :key="language.lang"
                    active-class="active"
                    :class="i > 0 ? 'mt-1' : ''"
                    dense
                    @click="selectedLanguage(language.lang)"
                  >
                    <v-list-item-icon class="mr-1">
                      <img
                        class="lang-list-item-img my-auto mr-3"
                        :src="language.flag"
                        alt=""
                      />
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>{{ language.name }}</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action
                      v-if="activeLanguage === language.lang"
                      class="ma-0"
                    >
                      <v-list-item-icon class="ma-0">
                        <v-icon x-small v-text="'fa-light fa-check'" />
                      </v-list-item-icon>
                    </v-list-item-action>
                  </v-list-item>
                </template>
              </v-list>
            </v-menu>
            <!------------ END: Language Menu ------------>

            <!------------ START: Logout ------------>
            <v-list-item
              v-b-popover.hover.right="{
                disabled: !mini
              }"
              dense
              class="mt-1"
              :title="$t('general.logout')"
              @click="logout"
            >
              <v-list-item-icon class="mr-1">
                <v-icon small v-text="'fa-light fa-arrow-right-from-bracket'" />
              </v-list-item-icon>
              <v-list-item-title>{{ $t("general.logout") }}</v-list-item-title>
            </v-list-item>
            <!------------ END: Logout ------------>
          </v-list>
        </v-menu>
        <!------------ END: User menu ------------>
      </v-list>
    </template>
    <!------------ END: Sidebar bottom ------------>
  </v-navigation-drawer>
</template>

<style scoped lang="scss">
.v-navigation-drawer {
  max-width: 230px;
  overflow-y: auto;
}

.v-list {
  .v-list-item {
    cursor: pointer;

    &.v-list-item--dense {
      .v-list-item__title {
        font-size: 12px;
      }

      .v-list-item__title,
      .v-list-item__subtitle {
        font-weight: 400;
      }
    }

    &.v-list-item--active {
      color: white;
      background-color: $color-connect-primary;
      border-radius: 8px;

      &::before {
        opacity: 0;
      }
    }

    &:not(.v-list-item--active):not(.v-list-item--disabled) {
      color: $color-connect-text;

      .v-list-item__icon {
        .v-icon {
          color: $color-connect-text;
        }
      }
    }

    &:hover,
    &:focus {
      &:not(.active):not(.project):not(.logo) {
        background-color: #f1f3ff;
        border-radius: 8px;
      }
    }

    &:hover::before,
    &:focus::before {
      opacity: 0;
    }
  }
}

.logo-height {
  height: 32px;
}

.logo-height-mini {
  height: 24px;
}

.lang-list-item-img {
  height: 16px;
  border-radius: 0.42rem;
}
</style>

<style lang="scss">
.sidebar {
  .v-navigation-drawer__content {
    overflow-y: visible;
    overflow-x: visible;
  }
}
</style>
